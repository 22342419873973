export enum InputType {
  Text = 'text',
  Price = 'price',
  Language = 'language',
  Hidden = 'hidden',
  Password = 'password',
  PasswordConfirmation = 'password_confirmation',
  Radio = 'radio',
  Select = 'select',
  CustomSelect = 'custom_select',
  Checkbox = 'checkbox',
  Combobox = 'combobox',
  Date = 'date',
  File = 'file',
  Slider = 'slider',
  DateMonth = 'date_month',
  DateTime = 'date_time',
  DateRange = 'date_range',
  DateRangeTime = 'date_range_time',
  DateRangeOneField = 'date_range_one_field',
  Autocomplete = 'autocomplete',
  Textarea = 'textarea',
  RichText = 'richtext',
  Decimal = 'decimal',
}
